import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import { App } from './App'
import reportWebVitals from './reportWebVitals'

document.documentElement.style.setProperty(
    '--vh',
    `${window.innerHeight * 0.01}px`
)

window.addEventListener('resize', () => {
    // Recalculate the value of 1vh in px and set the CSS variable
    document.documentElement.style.setProperty(
        '--vh',
        `${window.innerHeight * 0.01}px`
    )
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
